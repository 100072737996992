import { VerifiedAsLoggedInService } from './shared/services/verified-as-logged-in.service';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'manage-classes',
    loadChildren: () =>
      import('./components/manage-classes/manage-classes.module').then((mod) => mod.ManageClassesModule),
    canActivate: [VerifiedAsLoggedInService],
  },
  {
    path: 'wonde-manage-classes',
    loadChildren: () =>
      import('./components/wonde-manage-classes/wonde-manage-classes.module').then(
        (mod) => mod.WondeManageClassesModule
      ),
    canActivate: [VerifiedAsLoggedInService],
  },
  {
    path: 'download-revision/:slug',
    loadChildren: () => import('./pages/pages/pages.module').then((mod) => mod.PagesModule),
  },
  {
    path: 'subjects/:type',
    loadChildren: () => import('./pages/subjects/subjects.module').then((mod) => mod.SubjectsModule),
  },
  {
    path: 'progress/:classId',
    loadChildren: () =>
      import('./components/student-progress/student-progress.module').then((mod) => mod.StudentProgressModule),
    canActivate: [VerifiedAsLoggedInService],
  },
  {
    path: 'linked-students-results',
    loadChildren: () =>
      import('./components/linked-students-quiz-result/linked-students-quiz-result.module').then(
        (mod) => mod.LinkedStudentsQuizResultModule
      ),
    canActivate: [VerifiedAsLoggedInService],
  },
  {
    path: 'category/:subjectId/:categoryId/:type',
    loadChildren: () => import('./components/category/category.module').then((mod) => mod.CategoryModule),
    canActivate: [VerifiedAsLoggedInService],
  },
  {
    path: 'category/:subjectId/:categoryId/:type/:itemID',
    loadChildren: () => import('./components/category/category.module').then((mod) => mod.CategoryModule),
    canActivate: [VerifiedAsLoggedInService],
  },
  {
    path: 'videos/:categoryId',
    loadChildren: () =>
      import('./components/determine-route/determine-route.module').then((mod) => mod.DetermineRouteModule),
    canActivate: [VerifiedAsLoggedInService],
  },
  {
    path: 'videos/:categoryId/:videoID',
    loadChildren: () =>
      import('./components/determine-route/determine-route.module').then((mod) => mod.DetermineRouteModule),
    canActivate: [VerifiedAsLoggedInService],
  },
  {
    path: 'watch/:videoID',
    loadChildren: () =>
      import('./components/determine-route/determine-route.module').then((mod) => mod.DetermineRouteModule),
    canActivate: [VerifiedAsLoggedInService],
  },
  {
    path: 'quiz/:quizId',
    loadChildren: () =>
      import('./components/determine-route/determine-route.module').then((mod) => mod.DetermineRouteModule),
    canActivate: [VerifiedAsLoggedInService],
  },
  {
    path: 'presentation/:presentationId',
    loadChildren: () =>
      import('./components/determine-route/determine-route.module').then((mod) => mod.DetermineRouteModule),
    canActivate: [VerifiedAsLoggedInService],
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then((mod) => mod.ForgotPasswordModule),
  },
  {
    path: 'resetPassword',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then((mod) => mod.ResetPasswordModule),
  },
  {
    path: 'buy',
    loadChildren: () => import('./components/buy/buy.module').then((mod) => mod.BuyModule),
  },
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then((mod) => mod.HomeModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then((mod) => mod.RegisterModule),
  },
  {
    path: 'events',
    loadChildren: () => import('./pages/events/events.module').then((mod) => mod.EventModule),
  },
  {
    path: 'exam-tips',
    loadChildren: () => import('./pages/blog/blog.module').then((mod) => mod.BlogModule),
  },
  {
    path: 'revision-courses',
    loadChildren: () => import('./pages/liveCourses/liveCourses.module').then((mod) => mod.LiveCoursesModule),
  },
  {
    path: 'teachers',
    loadChildren: () => import('./pages/teacher/teacher.module').then((mod) => mod.TeacherModule),
  },
  {
    path: 'pricing',
    loadChildren: () => import('./pages/pricing/pricing.module').then((mod) => mod.PricingModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((mod) => mod.LoginModule),
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact/contact.module').then((mod) => mod.ContactModule),
  },
  {
    path: 'book-a-call',
    loadChildren: () => import('./pages/contact/contact.module').then((mod) => mod.ContactModule),
  },
  {
    path: 'sponsor-a-school',
    loadChildren: () => import('./pages/sponsor-school/sponsor-school.module').then((mod) => mod.SponsorSchoolModule),
  },
  {
    path: 'free-grinds',
    loadChildren: () => import('./pages/free-grinds/free-grinds.module').then((mod) => mod.FreeGrindsModule),
  },
  {
    path: 'our-mission',
    loadChildren: () => import('./pages/ourMission/ourMission.module').then((mod) => mod.OurMissionModule),
  },
  {
    path: 'our-journey',
    loadChildren: () => import('./pages/ourJourney/ourJourney.module').then((mod) => mod.OurJourneyModule),
  },
  {
    path: 'our-team',
    loadChildren: () => import('./pages/ourTeam/ourTeam.module').then((mod) => mod.OurTeamModule),
  },
  {
    path: 'faqs',
    loadChildren: () => import('./pages/faqs/faqs.module').then((mod) => mod.FaqsModule),
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/login/login.module').then((mod) => mod.LoginModule),
  },
  {
    path: 'already-logged-in',
    loadChildren: () =>
      import('./pages/already-logged-in/already-logged-in.module').then((mod) => mod.AlreadyLoggedInModule),
  },

  {
    path: 'account',
    loadChildren: () => import('./pages/myAccount/myAccount.module').then((mod) => mod.MyAccountModule),
    canActivate: [VerifiedAsLoggedInService],
  },
  {
    path: 'cmsAccountReset',
    loadChildren: () =>
      import('./pages/cms-account-reset/cms-account-reset.module').then((mod) => mod.CmsAccountResetModule),
  },

  {
    path: 'careers',
    loadChildren: () => import('./pages/careers/career.module').then((mod) => mod.CareerModule),
  },
  {
    path: 'confirmation',
    redirectTo: 'revision-courses/success',
  },
  {
    path: 'cart',
    redirectTo: 'revision-courses/browse',
  },
  {
    path: 'myAccount/settings',
    redirectTo: 'account/settings',
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
